.input {
    width: 100%;
    border: none;
    background-color: rgba(250, 250, 249, 1);
    border-radius: var(--border-radius-16);
    padding: var(--space-20) var(--space-30);
    outline-color: var(--orange);
    color: var(--dark-brown);
    font-size: var(--font-size-16)
}

.input::placeholder {
    font-size: var(--font-size-14);
    line-height: var(--line-height-28);
    color: var(--light-gray);
}