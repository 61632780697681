:root {
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;

    --font-size-10: 1rem;
    --font-size-12: 1.2rem;
    --font-size-13: 1.3rem;
    --font-size-14: 1.4rem;
    --font-size-16: 1.6rem;
    --font-size-18: 1.8rem;
    --font-size-20: 2rem;
    --font-size-24: 2.4rem;
    --font-size-30: 3rem;
    --font-size-32: 3.2rem;
    --font-size-40: 4rem;
    --font-size-48: 4.8rem;
    --font-size-56: 5.6rem;
    --font-size-64: 6.4rem;
    --font-size-72: 7.2rem;
    --font-size-80: 8rem;

    --line-height-16: 1.6rem;
    --line-height-18: 1.8rem;
    --line-height-20: 2rem;
    --line-height-24: 2.4rem;
    --line-height-26: 2.6rem;
    --line-height-28: 2.8rem;
    --line-height-32: 3.2rem;
    --line-height-40: 4rem;
    --line-height-48: 4.8rem;
    --line-height-56: 5.6rem;
    --line-height-60: 6rem;
    --line-height-64: 6.4rem;
    --line-height-72: 7.2rem;
    --line-height-88: 8.8rem;
    --line-height-96: 9.6rem;
    --line-height-160: 16rem;
}

.heading-1,
.heading-2,
.heading-3 {
    font-weight: var(--font-weight-700);
}

.heading-1 {
    font-size: var(--font-size-80);
    line-height: var(--line-height-88);

    @media (max-width: 35em) {
        font-size: var(--font-size-64);
        line-height: var(--line-height-72);
    }
}

.heading-2 {
    font-size: var(--font-size-64);
    line-height: var(--line-height-70);

    /* @media (max-width: 43.75em) {
        font-size: var(--font-size-56);
        line-height: var(--line-height-64);
    } */

    @media (max-width: 35em) {
        font-size: var(--font-size-48);
        line-height: var(--line-height-56);
    }
}

.heading-3 {
    font-size: var(--font-size-20);
    line-height: var(--line-height-40);
}

.subtitle-xl,
.subtitle-lg,
.subtitle-md,
.subtitle-sm {
    font-weight: var(--font-weight-600);
}

.subtitle-xl {
    font-size: var(--font-size-30);
    line-height: var(--line-height-60);

    /* @media (max-width: 31.25em) {
        font-size: var(--font-size-20);
        line-height: var(--line-height-23);
    } */
}

.subtitle-lg {
    font-size: var(--font-size-24);
    line-height: var(--line-height-50);
}

.subtitle-md {
    font-size: var(--font-size-20);
    line-height: var(--line-height-40);
}

.subtitle-sm {
    font-size: var(--font-size-16);
    line-height: var(--line-height-32);
}

.paragraph-xl,
.paragraph-lg,
.paragraph-md,
.paragraph-sm {
    font-weight: var(--font-weight-400);
}

.paragraph-xl {
    font-size: var(--font-size-24);
    line-height: var(--line-height-32);
}

.paragraph-lg {
    font-size: var(--font-size-20);
    line-height: var(--line-height-40);

    /* @media (max-width: 35em) {
        font-size: var(--font-size-16);
        line-height: var(--line-height-24);
    } */
}

.paragraph-md {
    font-size: var(--font-size-18);
    line-height: var(--line-height-28);
}

.paragraph-sm {
    font-size: var(--font-size-14);
    line-height: var(--line-height-23);

    /* @media (max-width: 31.25em) {
        font-size: var(--font-size-12);
        line-height: var(--line-height-16);
    } */
}

.overline {
    font-size: var(--font-size-10);
    line-height: var(--line-height-16);
}
