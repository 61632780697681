@font-face {
    font-family: "Poppins", sans-serif;
    src: url(../../assets/fonts/Poppins-Bold.ttf) format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Poppins", sans-serif;
    src: url(../../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Poppins", sans-serif;
    src: url(../../assets/fonts/Poppins-Regular.ttf) format("truetype");
    font-weight: 400;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-family: "Poppins", sans-serif;
    font-size: 62.5%; /* it sets base font size to 10px */
    @media (max-width: 50em) {
        font-size: 50%;
    }

    @media (max-width: 31.25em) {
        font-size: 40%;
    }

}

span {
    color: var(--orange);
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}
