/* Buttons */
.btn {
    cursor: pointer;
    border: none;
}

.btn-small {
    height: var(--space-50);
    font-size: var(--font-size-18);
    border-radius: var(--border-radius-133);
    padding-left: var(--space-36);
    padding-right: var(--space-36);
}

.btn-medium {
    height: var(--space-56);
    font-size: var(--font-size-16);
    border-radius: var(--border-radius-163);
    padding-right: var(--space-36);
    padding-left: var(--space-36);
}

.btn-large {
    height: var(--space-74);
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-600);
    border-radius: var(--border-radius-163);
    padding-right: var(--space-56);
    padding-left: var(--space-56);

    @media (max-width: 37.5em) {
        height: var(--space-56);
        font-size: var(--font-size-16);
        border-radius: var(--border-radius-163);
        padding-right: var(--space-36);
        padding-left: var(--space-36);
    }
}

.btn-green {
    background-color: var(--light-green);
    color: var(--white);
}

.btn-orange {
    background-color: var(--orange);
    color: var(--white);
}

.btn-black {
    background-color: var(--dark-brown);
    color: var(--white);
}

.btn-gray {
    background-color: #ecf0f1;
    color: var(--dark-brown);
}

.btn-white {
    background-color: var(--white);
    color: var(--dark-brown);
    border: 0.1rem solid #b6a38b;
}
